<template>
  <el-dialog
      :title="title"
      :visible.sync="imgDialogVisible"
      width="800px"
      :close-on-click-modal="false"
      :append-to-body="true"
      v-drag-dialog
      @close="close">
    <div class="pageContainer">
      <img :src="imgUrl" alt="">
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="close">关闭</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "showImg",
  data() {
    return {
      title: '图片简介',
      imgUrl: '',
      imgDialogVisible: false
    }
  },
  methods: {
    show(options) {
      this.title = options.title
      this.imgUrl = options.imgUrl
      this.imgDialogVisible = true
    },
    close() {
      this.imgDialogVisible = false
    }
  }
}
</script>

<style scoped>
.pageContainer img {
  width: 100%;
}
</style>